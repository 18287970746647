import useApi from '../api';

const useUserService = () => {
    const { api } = useApi();

    return {
        update: (data) => {
            data.append('_method', 'PUT');
            return api
                .post(`user/${data.get('id')}`, data, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((resp) => resp.data);
        }
    };
};
export default useUserService;
