export const initialState = {
    column: 'name',
    order: 'asc'
};

const producerReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_COLUMN_ORDER_PRODUCER':
            return {
                ...state,
                column: actions.column,
                order: actions.order
            };
        default:
            return state;
    }
};

export default producerReducer;
