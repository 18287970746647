import React, { useEffect, useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Content, RightSidebar, RightSidebarNav, SidebarGap } from './styles';
import useAuthService from 'services/auth';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from 'components/molecules/alerts/Snackbar';
import DashboardNavbar from '../../../../organisms/layout/navbars/DashboardNavbar';
import DashboardSidebar from '../../../../organisms/layout/navbars/DashboardSidebar';
import useDashboard from '../../../../../hooks/useDashboard';
import NavbarIcons from '../../../../molecules/buttons/NavbarIcons';
import LoadingComponent from '../../../LoadingComponent';
import Dialog from '../../../../molecules/alerts/Dialog';
import SettingsModal from '../../../../molecules/modals/SettingsModal';

const DashboardLayout = () => {
    const { logged } = useAuthService();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dashboard = useDashboard();
    const [isLaticinio, setIsLaticinio] = useState(false);
    const [checkingLogin, setCheckingLogin] = useState(true);
    const auth = useSelector((state) => state.auth);

    const checkAuth = async () => {
        if (auth?.loggedIn) {
            setCheckingLogin(false);
            return;
        }
        const loggedData = await logged();

        if (!loggedData) {
            navigate('/login');
            return;
        }

        return loggedData;
    };

    useEffect(() => {
        checkAuth().then((loggedData) => {
            dispatch({
                type: 'SET_LOGIN',
                payload: {
                    email: loggedData.email,
                    name: loggedData.name,
                    id: loggedData.id,
                    studentId: loggedData?.id_student,
                    enterpriseId: loggedData?.id_enterprise,
                    image: loggedData.image,
                    level: loggedData?.levelExperience,
                    idRole: loggedData.id_role,
                    producerId: loggedData.producer_id,
                    dairyId: loggedData.dairy_id,
                    dairyLogo: loggedData.dairy_logo,
                    producerId: loggedData.producer_id,
                    farmName: loggedData.farm_name,
                    totalDairy: loggedData.totalDairy,
                    totalProducer: loggedData.totalProducer
                }
            });
            loggedData.id_role == 2 ? setIsLaticinio(true) : setIsLaticinio(false);
            setCheckingLogin(false);
        });
    }, []);
    if (checkingLogin) {
        return <LoadingComponent />;
    }

    return (
        <>
            <Container rightSidebar={dashboard.rightSidebar}>
                <DashboardSidebar />
                <SidebarGap />
                <Content>
                    <DashboardNavbar rightSidebar={dashboard.rightSidebar} />
                    <React.Suspense fallback={<LoadingComponent />}>
                        <Outlet />
                    </React.Suspense>
                    <Snackbar />
                    <Dialog useReducer={true} />
                </Content>
            </Container>
            {dashboard.rightSidebar && (
                <RightSidebar profile={auth.user.idRole} isLaticinio={isLaticinio}>
                    <RightSidebarNav>
                        <NavbarIcons />
                    </RightSidebarNav>
                    {dashboard.rightSidebarContent}
                </RightSidebar>
            )}
            <SettingsModal />
        </>
    );
};

DashboardLayout.propTypes = {
    hiddenBottomNavbar: PropTypes.bool
};

export default DashboardLayout;
