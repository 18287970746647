import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import snackbarReducer from './snackbarReducer';
import confirmDialogReducer from './confirmDialogReducer';
import tabsDiagnosticReducer from './tabsDiagnosticReducer';
import tabsNSMReducer from './tabsDiagnosticReducer';
import producerReducer from './producerReducer';
import biHomeReducer from './biHomeReducer';
import dairyReducer from './dairyReducer';
const reducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    snackbar: snackbarReducer,
    confirmDialog: confirmDialogReducer,
    tabsDiagnostic: tabsDiagnosticReducer,
    producer: producerReducer,
    biHome: biHomeReducer,
    tabsNSM: tabsNSMReducer,
    dairy: dairyReducer
});

export default reducer;
