import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Dropzone, Files, File, DropzoneText } from './styles';
import { Tooltip, Typography } from '@mui/material';
import useSnackbar from '../../../../hooks/useSnackbar';

const DropzoneInput = ({ dropText = 'Arraste e solte arquivos aqui', onDrop, error, errorMessage, maxFileSize }) => {
    const [files, setFiles] = useState([]);

    const snackbar = useSnackbar();

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxSize: maxFileSize * 1000000,
        onDrop: (acceptedFiles, fileRejections) => {
            setFiles([
                ...files,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            ]);
            if (fileRejections.length > 0) {
                if (fileRejections[0].errors[0].code === 'file-too-large') {
                    snackbar(`O arquivo não deve ser maior que ${maxFileSize}MB`, 'error');
                }
            }
        }
    });
    const handleDeleteFile = (toDelete) => (evt) => {
        evt.stopPropagation();
        setFiles(files.filter((file) => file.preview !== toDelete));
    };

    useEffect(() => {
        onDrop(files);
    }, [files]);
    return (
        <>
            <Dropzone {...getRootProps({ className: 'dropzone' })} error={error}>
                <input {...getInputProps()} />
                <DropzoneText>{dropText}</DropzoneText>
                {files.length > 0 && (
                    <Files>
                        {files.map((file, key) => (
                            <Tooltip title={'Remover'} key={`file-${key}`} placement={'top'}>
                                <File
                                    src={file.preview}
                                    // Revoke data uri after image is loaded
                                    onClick={handleDeleteFile(file.preview)}
                                />
                            </Tooltip>
                        ))}
                    </Files>
                )}
            </Dropzone>
            {error && (
                <Typography variant={'caption'} color={'error'}>
                    {errorMessage ?? 'Erro, insira um arquivo'}
                </Typography>
            )}
        </>
    );
};

DropzoneInput.propTypes = {
    values: PropTypes.object,
    dropText: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    maxFileSize: PropTypes.number
};

export default DropzoneInput;
