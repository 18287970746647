import styled from 'styled-components';

export const CloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 12px;
    padding: 5px;
    cursor: pointer;
    color: #294732;
    :hover {
        background: #ebefed;
    }
    :active {
        filter: brightness(0.95);
    }
`;
