import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import trilhasLogo from 'assets/images/trilhas-logo.png';
import imgLogo from 'assets/images/logo.png';
import Spacer from 'components/atoms/structure/Spacer';

const AuthLayout = ({ children }) => {
    return (
        <Container>
            <div>
                <img src={trilhasLogo} alt="" />
            </div>
            <main>
                <div className="logo">
                    <img src={imgLogo} alt="" />
                </div>
                <Spacer height="50px" />
                <div>{children}</div>
            </main>
        </Container>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthLayout;
