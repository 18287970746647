import { Container } from './styles';
import Logo from '../../../components/atoms/svgs/Logo';
import { Link } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import Meta from '../../../components/templates/Seo/Meta';

const Error404 = () => {
    return (
        <Container>
            <Meta title={'404'} />
            <Logo />
            <h1>404</h1>
            <p>A página solicitada não existe</p>
            <Link component={ReactLink} to={'/'} sx={{ textDecoration: 'none', fontWeight: 600 }}>
                Voltar para o Início
            </Link>
        </Container>
    );
};
export default Error404;
