import useApi from '../api';

const useNotificationService = () => {
    const { api } = useApi();
    return {
        search: (search, path = null) => api.get(path || 'notifications', { params: { search } }).then((resp) => resp.data),
        find: (id) => api.get(`notifications/${id}`).then((resp) => resp.data),
        newNotifications: () => api.get('/notifications/new-notifications', {}).then((resp) => resp.data),
        markAsRead: (id) => api.put(`/notifications/${id}/mark-as-read`).then((resp) => resp.data)
    };
};

export default useNotificationService;
