import React, { useEffect, useRef, useState } from 'react';
import useAuthService from 'services/auth';
import IconButton from '../IconButton';
import { NotificationsOutlined } from '@mui/icons-material';
import QuestionIcon from '../../../../assets/icons/Question.svg';
import GearIcon from '../../../../assets/icons/GearSix.svg';
import { Container, Notification, Badge } from './styles';
import NotificationsModal from '../../../../components/molecules/modals/NotificationsModal';
import { Divider, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDashboard from '../../../../hooks/useDashboard';
import { useSelector } from 'react-redux';
import useNotificationService from '../../../../services/notifications';

const NavbarIcons = () => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const settingsButton = useRef();
    const navigate = useNavigate();
    const { logout } = useAuthService();
    const { openSettingsModal } = useDashboard();
    const user = useSelector((state) => state.auth.user);
    const notificationService = useNotificationService();
    const [openModalNotifications, setOpenModalNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const handleOpenSettings = (evt) => {
        setMenuAnchor(evt.currentTarget);
        setShowSettingsMenu(true);
    };
    const handleCloseSettings = () => {
        setMenuAnchor(null);
        setShowSettingsMenu(false);
    };

    const handleSettingsModal = () => {
        handleCloseSettings();
        openSettingsModal();
    };
    const handleLogout = () => {
        logout();
        handleCloseSettings();
        navigate('/login');
    };

    const getNotifications = async () => {
        if (user.idRole === 1) {
            notificationService.newNotifications().then((resp) => {
                setNotifications(resp.data);
            });
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    setTimeout(() => {
        getNotifications();
    }, 120000);

    const handleOpenModalNotifications = () => {
        setOpenModalNotifications(true);
    };
    const handleClonseModalNotifications = () => {
        setOpenModalNotifications(false);
    };

    const handleClickHelp = () => {
        let location = '';
        if (user.idRole == 1) {
            location = '/admin/ajuda';
        } else if (user.idRole == 2) {
            location = '/laticinio/ajuda';
        } else {
            location = '/produtor/ajuda';
        }
        navigate(location);
    };

    return (
        <>
            <Container notification={notifications.length > 0 ? 1 : 0}>
                {user.idRole == 1 && (
                    <Notification>
                        <IconButton icon={<NotificationsOutlined />} onClick={() => handleOpenModalNotifications()} />
                        {notifications.length > 0 && <Badge />}
                    </Notification>
                )}
                <IconButton icon={QuestionIcon} onClick={handleClickHelp} />
                <IconButton ref={settingsButton} icon={GearIcon} onClick={handleOpenSettings} />
            </Container>
            <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={showSettingsMenu}
                onClose={handleCloseSettings}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={handleSettingsModal}>Configurações do Perfil</MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
            <NotificationsModal open={openModalNotifications} handleClose={handleClonseModalNotifications} notifications={notifications} />
        </>
    );
};
export default NavbarIcons;
NavbarIcons.propTypes = {};
