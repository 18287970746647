import React from 'react';
import { Button, TransparentButton } from './styles';
import PropTypes from 'prop-types';

const IconButton = ({ icon, variant = 'default', color = 'default', ...props }) => {
    switch (variant) {
        case 'transparent':
            const FontColors = {
                default: '#294732',
                error: '#CB1E1E'
            };
            const BgColors = {
                default: '#ebefed',
                error: '#F8EAEA'
            };
            return (
                <TransparentButton color={FontColors[color]} bgColor={BgColors[color]} {...props}>
                    {React.isValidElement(icon) ? <>{icon}</> : <img src={icon} alt={''} />}
                </TransparentButton>
            );
        default:
            return <Button {...props}>{React.isValidElement(icon) ? <>{icon}</> : <img src={icon} alt={''} />}</Button>;
    }
};
export default IconButton;

IconButton.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    variant: PropTypes.oneOf(['default', 'transparent']),
    color: PropTypes.oneOf(['default', 'error'])
};
