import BaseModal from '../BaseModal';
import useDashboard from 'hooks/useDashboard';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { formikProps } from 'utils/helpers/formikProps';
import UploadWithPreview from 'components/atoms/formInputs/UploadWithPreview';
import * as Yup from 'yup';
import useUserService from 'services/user';
import { formikFormData } from 'utils/helpers/formikFormData';
import { useEffect, useState } from 'react';
import snackbar from '../../alerts/Snackbar';
import useSnackbar from 'hooks/useSnackbar';

const settingsValidation = Yup.object().shape({
    email: Yup.string().required('O email é obrigatório').email('Insira um email válido'),
    name: Yup.string().required('O nome é obrigatório'),
    password: Yup.string().when('newPassword', {
        is: (newPassword) => typeof newPassword !== 'undefined',
        then: Yup.string().required('É necessário informar sua senha atual.')
    }),
    newPassword: Yup.string(),
    confirmPassword: Yup.string()
        .when('newPassword', {
            is: (newPassword) => typeof newPassword !== 'undefined',
            then: Yup.string().required('Você deve confirmar a nova senha')
        })
        .oneOf([Yup.ref('newPassword'), null], 'As senhas não são iguais')
});
const SettingsModal = () => {
    const { closeSettingsModal, ...dashboard } = useDashboard();
    const [showConfirm, setShowConfirm] = useState(false);
    const auth = useSelector((state) => state.auth);
    const userService = useUserService();
    const dispatch = useDispatch();
    const snackbar = useSnackbar();

    const initialValues = {
        id: auth.user.id,
        image: auth.user.image,
        email: auth.user.email,
        name: auth.user.name,
        password: '',
        newPassword: '',
        confirmPassword: '',
        toUpload: []
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: settingsValidation,
        onSubmit: () => {
            userService
                .update(formikFormData(formik.values))
                .then((resp) => {
                    if (!resp.error) {
                        dispatch({
                            type: 'SET_LOGIN',
                            payload: {
                                id: resp.user.id,
                                idRole: resp.user.id_role,
                                image: resp.user.image,
                                producerId: resp.user.producer_id,
                                dairyId: resp.user.dairy_id,
                                dairyLogo: resp.user.dairy_logo,
                                producerId: resp.user.producer_id,
                                farmName: resp.user.farm_name,
                                name: resp.user.name,
                                email: resp.user.email,
                                disabled: resp.user.disabled,
                                totalProducer: resp.user.totalProducer,
                                totalDairy: resp.user.totalDairy
                            }
                        });
                        snackbar('Alterações Salvas', 'success');
                    }
                })
                .then(() => {
                    closeSettingsModal();
                    setShowConfirm(false);
                    formik.resetForm();
                });
        }
    });
    const handleFileChange = (files, url) => {
        formik.setFieldValue('toUpload', files[0]);
        formik.setFieldValue('image', url);
    };

    const handleCloseModal = () => {
        formik.resetForm();
        setShowConfirm(false);
        closeSettingsModal();
    };

    const handleSubmit = () => {
        formik.handleSubmit();
    };

    const handleConfirm = () => {
        setShowConfirm(true);
    };
    useEffect(() => {
        if (dashboard.settingsModal) {
            formik.setValues({
                id: auth.user.id,
                image: auth.user.image,
                email: auth.user.email,
                name: auth.user.name,
                password: '',
                newPassword: '',
                confirmPassword: '',
                toUpload: []
            });
        }
    }, [dashboard.settingsModal]);
    return (
        <BaseModal
            title={'Configurações do Perfil'}
            open={dashboard.settingsModal}
            handleClose={handleCloseModal}
            withActions={
                showConfirm ? (
                    <>
                        <Typography sx={{ marginRight: '10px' }}>Realmente deseja salvar as alterações? </Typography>
                        <Button variant={'outlined'} onClick={handleCloseModal}>
                            <b>Cancelar</b>
                        </Button>
                        <Button variant={'contained'} onClick={handleSubmit} disabled={!formik.isValid}>
                            <b>Salvar</b>
                        </Button>
                    </>
                ) : (
                    <>
                        <Button variant={'outlined'} onClick={handleCloseModal}>
                            <b>Cancelar</b>
                        </Button>
                        <Button variant={'contained'} onClick={handleConfirm} disabled={!formik.isValid}>
                            <b>Salvar</b>
                        </Button>
                    </>
                )
            }
        >
            <form onSubmit={formik.handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <UploadWithPreview
                            rounded
                            helperText={formik.touched.image && <>{formik.errors.image}</>}
                            error={formik.touched.image && Boolean(formik.errors.image)}
                            text={'Inserir Imagem'}
                            value={formik.values.image}
                            defaultValue={auth.user.image}
                            onFileChange={handleFileChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...formikProps('name', formik)}
                            fullWidth
                            size={'small'}
                            label={'Nome'}
                            disabled={auth.user.idRole !== 1}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...formikProps('email', formik)}
                            fullWidth
                            size={'small'}
                            label={'Email'}
                            disabled={auth.user.idRole !== 1}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formikProps('password', formik)}
                            fullWidth
                            size={'small'}
                            label={'Senha Atual'}
                            inputProps={{ type: 'password' }}
                            required={Boolean(formik.values.newPassword)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formikProps('newPassword', formik)}
                            fullWidth
                            size={'small'}
                            label={'Nova Senha'}
                            inputProps={{ type: 'password' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            {...formikProps('confirmPassword', formik)}
                            fullWidth
                            size={'small'}
                            label={'Confirmar nova senha'}
                            inputProps={{ type: 'password' }}
                            required={Boolean(formik.values.newPassword)}
                        />
                    </Grid>
                </Grid>
            </form>
        </BaseModal>
    );
};

export default SettingsModal;

SettingsModal.propTypes = {};
