import React, { useEffect, useState } from 'react';
import { Container, UserAvatar } from './styles';
import Logo from '../../../../atoms/svgs/Logo';
import useDashboard from '../../../../../hooks/useDashboard';
import NavbarIcons from '../../../../molecules/buttons/NavbarIcons';
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import useHomeService from 'services/home';
import { useDispatch, useSelector } from 'react-redux';
import useCategoryService from 'services/category';

const DashboardNavbar = ({ rightSidebar }) => {
    const dashboard = useDashboard();
    const { getCategory } = useCategoryService();
    const { getUserData } = useHomeService();
    const [userName, setUserName] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [categoryOptions, setCategoryOptions] = useState([]);
    const categoryFilter = useSelector((state) => state.dashboard.categoryFilter);

    const getUserInfo = async () => {
        const userInfo = await getUserData();
        return userInfo;
    };

    function getCategoryOptions() {
        getCategory().then((resp) => setCategoryOptions(resp));
    }

    useEffect(() => {
        getCategoryOptions();
    }, []);

    function renderOptionsCategory() {
        let arrayOptions = categoryOptions || [];
        return arrayOptions.map((desc) => (
            <MenuItem key={desc.id} value={desc.id}>
                {desc.description}
            </MenuItem>
        ));
    }

    useEffect(() => {
        if (user?.id_role == 2) {
            getUserInfo().then((resp) => {
                setUserAvatar(resp.dairyInfo.logo);
                setUserName(resp.dairyInfo.name);
            });
        }
    }, []);
    console.log(user.image);
    return (
        <Container rightSidebar={rightSidebar}>
            <div className={'logotipo'}>
                <Logo />
            </div>
            <div>
                <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                        <InputLabel id="filterFase">Fase</InputLabel>
                        <Select
                            size={'small'}
                            labelId="filterFase"
                            id="filterFase"
                            value={categoryFilter}
                            label="Fase"
                            onChange={(e) => dispatch({ type: 'SET_CATEGORY_FILTER', payload: e.target.value })}
                        >
                            {renderOptionsCategory()}
                        </Select>
                    </FormControl>
                </Box>
                {!dashboard.rightSidebar && (
                    <>
                        <NavbarIcons />
                        <UserAvatar
                            src={
                                user.image ||
                                `https://eu.ui-avatars.com/api/?background=015641&color=ffffff&bold=true&rounded=true&font-size=0.6&size=128&length=1&name=${user.name}`
                            }
                            alt={user.name || 'user'}
                        />
                    </>
                )}
            </div>
        </Container>
    );
};

export default DashboardNavbar;
