import useApi from '../api';

const useHomeService = () => {
    const { api } = useApi();
    return {
        getUserData: () => api.get('user_data').then((resp) => resp.data),
        getBiHome: (id, category) => api.get('bi/home', { params: { id, category } }).then((resp) => resp.data)
    };
};

export default useHomeService;
