import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

export const FileUpload = styled.label`
    width: 150px;
    padding: 5px;
    background-color: #3a6647;
    color: #ffffff;
    font-weight: bold;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    input[type='file'] {
        display: none;
    }
`;

export const FilePreview = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.rounded ? '132px' : '200px')};
    height: ${(props) => (props.rounded ? '132px' : '128px')};

    border-radius: ${(props) => (props.rounded ? '50%' : '8px')};

    background-color: ${(props) => (props.hasImage ? '#ebebeb' : '#ebebeb')};
    overflow: hidden;
    > img.preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 10;
    }
    > img.blur {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;

        opacity: 0.3;
        filter: blur(2px);
    }
`;
export const ClearButton = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 2px;
    cursor: pointer;
    z-index: 999;

    :hover {
        opacity: 1;
        background: rgba(235, 239, 237, 0.6);
    }

    :active {
        filter: brightness(0.95);
    }
`;

export const HelperText = styled.span`
    font-size: 9pt;
    color: ${(props) => (props.hasError ? '#cf322e' : '#808080')};
    text-align: center;
`;
