import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import useSnackbar from '../hooks/useSnackbar';
import { removeCookie } from '../utils/cookies';

const env = process.env;

let refreshingToken = false;
let refreshPromise = null;

async function refreshAccessToken() {
    if (!refreshingToken) {
        refreshingToken = true;

        const refreshTokenEndpoint = `${env.REACT_APP_BASE_URL}/refresh`;

        try {
            const response = await axios.post(
                refreshTokenEndpoint,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`
                    }
                }
            );

            Cookies.set('token', response.data.token);
            refreshingToken = false;

            return response;
        } catch (err) {
            refreshingToken = false;
            console.error(err);
            throw err;
        }
    } else {
        if (!refreshPromise) {
            refreshPromise = new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (!refreshingToken) {
                        clearInterval(interval);
                        refreshPromise = null;
                        resolve();
                    }
                }, 100);
            });
        }

        await refreshPromise;
    }
}

const useApi = (responseType = 'JSON') => {
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const api = axios.create({
        baseURL: env.REACT_APP_BASE_URL,
        responseType: responseType
    });

    api.interceptors.request.use(
        (config) => {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    common: {
                        ...config.headers.common,
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        Authorization: `Bearer ${Cookies.get('token')}`,
                        Personification: Cookies.get('personification')
                    }
                }
            };
        },
        (error) => Promise.reject(error)
    );

    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            switch (error.response.status) {
                case 401:
                    const originalRequest = error.config;
                    if (!originalRequest._retry) {
                        originalRequest._retry = true;
                        try {
                            await refreshAccessToken();
                            originalRequest.headers.Authorization = `Bearer ${Cookies.get('token')}`;
                            return axios(originalRequest);
                        } catch (err) {
                            removeCookie('token');
                            navigate('/login');
                        }
                    }
                    snackbar(error.response.data.error, 'error');
                    break;
                default:
                    if (error.response.data.errorValidate) {
                        error.response.data.errorValidate = Object.values(
                            Object.keys(error.response.data.errorValidate).map(function (item) {
                                return error.response.data.errorValidate[item][0];
                            })
                        );

                        snackbar(error.response.data.errorValidate[0], 'error');
                    }

                    if (error.response.data.error) {
                        snackbar(error.response.data.error, 'error');
                    }
                    break;
            }
            return Promise.reject(error);
        }
    );

    return { api };
};

export default useApi;
