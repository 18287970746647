import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 24px 18px;
    gap: 26px;

    position: fixed;
    width: 80px;
    left: 24px;
    top: 170px;

    background: #ebefed;
    border-radius: 100px;
`;

export const MenuItem = styled.div`
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;

        &.stroke {
            > * {
                stroke: #294732;
            }
        }

        &.fill {
            > * {
                fill: #294732;
            }
        }
    }

    > .title {
        color: #294732;
        text-align: center !important;
        font-size: 14px;
        font-weight: 600;
        display: none;
    }

    &:hover {
        > .icon {
            background-color: #b6cbbc;
        }

        > .title {
            display: block;
        }
    }

    &:active,
    &.active {
        .icon {
            background-color: #294732;

            &.stroke {
                > * {
                    stroke: #fff;
                }
            }

            &.fill {
                > * {
                    fill: #fff;
                }
            }
        }

        > .title {
            display: block;
        }
    }
`;
