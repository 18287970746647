import React from 'react';

const Logo = () => {
    return (
        <>
            <svg width="138" height="32" viewBox="0 0 138 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_813_8042)">
                    <path
                        d="M110.842 13.6795C110.137 9.91191 106.977 6.99532 102.512 7.19026C96.5774 7.45268 93.4397 12.3374 94.5043 18.0244C95.2728 22.1293 98.613 24.731 102.842 24.5473C108.604 24.2962 111.97 19.7039 110.842 13.6795ZM102.864 20.8135C101.125 20.8885 99.9289 19.6514 99.5727 17.732C99.0929 15.1753 99.7264 11.0403 102.557 10.9166C104.502 10.8304 105.485 12.4161 105.77 13.9307C106.294 16.7235 105.488 20.6935 102.864 20.8098V20.8135Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M65.0346 16.6973L65.2333 8.01503C63.7562 7.57267 61.5744 7.20154 59.5613 7.20154C51.3701 7.20154 48.6747 13.1697 49.6681 18.7667C50.2829 22.2268 52.7384 24.5324 55.7412 24.5324C57.6869 24.5324 59.7412 23.6177 60.7909 20.8698C60.8884 21.6908 60.8209 22.8641 60.9071 23.5989C61.0271 24.1462 61.1845 24.5436 62.103 24.5436H65.5069C65.0721 22.4743 65.0046 18.8342 65.0346 16.6973ZM60.1723 14.8641C60.1086 18.9354 58.5941 20.5961 57.1283 20.5961C55.7637 20.5961 54.9165 19.479 54.6278 17.8482C54.0805 14.7629 55.715 10.8941 58.8902 10.8941C59.4001 10.8941 59.8574 10.9616 60.2436 11.0291L60.1723 14.8604V14.8641Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M66.3992 18.4293C67.0253 21.7883 69.5257 24.5512 72.8359 24.5512C74.8153 24.5512 76.1724 23.7489 77.1621 21.9982H77.2296L77.2146 23.4677C77.1021 26.8979 75.4901 28.0151 73.0309 28.0151C71.5988 28.0151 69.5182 27.6327 68.4948 27.0704L68.1424 29.897C68.1424 29.897 68.0299 30.8867 69.0159 31.3328C70.268 31.7527 71.9287 32.0038 73.6682 32.0038C75.8837 32.0038 78.2268 31.5502 79.7788 30.0807C81.4657 28.4349 81.9306 25.6383 81.9568 22.4893L81.9943 8.3937C80.0899 7.65893 77.9643 7.20532 75.6101 7.20532C68.3411 7.20532 65.3683 12.8023 66.3955 18.4331L66.3992 18.4293ZM77.1546 15.9476C77.1133 18.8154 75.835 20.6674 74.2343 20.6674C72.731 20.6674 71.76 19.4078 71.4526 17.6608C70.8828 14.4406 72.4011 11.0479 75.3701 11.0479C76.0524 11.0479 76.7947 11.1866 77.1996 11.3628L77.1546 15.9476Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M31.835 12.1613C31.2876 9.05726 28.5023 7.19409 25.1246 7.19409C19.0852 7.19409 16.3598 12.435 17.252 17.5184C18.0243 21.8857 21.0046 24.5437 25.7469 24.5437C27.4226 24.5437 29.2595 24.3862 30.939 23.6215C31.5538 23.2503 31.4638 22.3581 31.4638 22.3581L31.4001 19.4827C30.1817 20.1313 28.0599 20.7611 26.253 20.7611C24.8209 20.7611 23.6175 20.3187 22.819 19.464C22.3917 18.9879 22.088 18.2381 21.9718 17.7583C28.7235 17.9307 32.581 16.3937 31.835 12.1613ZM21.8144 14.1744C21.8856 12.6374 22.879 10.7292 25.1658 10.7292C26.2942 10.7292 27.0777 11.3103 27.2202 12.1275C27.4976 13.6983 25.6757 14.2081 21.8144 14.1744Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M93.6082 7.19034C93.3345 7.1716 93.0646 7.16785 92.7909 7.16785C92.1649 7.16785 91.5838 7.3103 91.0477 7.56147C90.9577 7.59896 90.8753 7.64395 90.7965 7.68893C89.4057 8.4462 88.4123 10.1519 87.8125 11.7302C87.8125 11.7302 87.865 9.55585 87.8275 7.55772L83.91 7.55023C83.91 7.55023 83.1827 7.58772 83.1639 8.40496C83.1639 17.3347 83.1377 23.5202 83.1377 23.5202C83.1377 23.5202 83.1377 24.5174 84.3748 24.5587H88.2961L88.3935 18.2269C88.4423 15.0929 89.3608 12.3862 92.2061 12.0638C93.2708 12.0263 93.9418 11.4115 94.223 11.0854C95.3701 9.7208 95.3701 7.38903 95.3701 7.38903C94.7178 7.26906 94.1293 7.20533 93.6044 7.18284L93.6082 7.19034Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M47.9925 11.6814C47.4789 8.75355 45.5857 7.19404 42.9916 7.19404C40.806 7.19404 39.2352 8.09001 38.1143 9.66077L37.9494 9.94193L37.9381 1.2484C37.9531 0.0337748 36.7385 0.00378418 36.7385 0.00378418H33.0422L33.0347 23.3327C33.0347 23.3327 33.1096 24.5511 34.3055 24.5511H37.9381L38.0281 16.7086C38.0431 13.2896 39.4002 11.1303 41.1396 11.1303C42.3692 11.1303 42.9541 11.9326 43.1565 13.0834C43.2802 13.7807 43.3402 14.5118 43.3215 15.1753L43.4602 23.404C43.4602 23.404 43.4864 24.5736 44.6635 24.5511H48.3636L48.2324 14.7554C48.2324 13.7807 48.1387 12.4874 47.9962 11.6851L47.9925 11.6814Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M15.5464 18.2681C14.3018 16.0525 13.1321 13.9944 12.2362 13.2147C13.8069 12.4762 16.8735 10.7292 16.8885 7.11533C16.9072 2.38056 13.9869 0.0862777 7.36645 0.0487895H7.37395L0.112465 0.0187988L0.157451 4.62986L0.101218 17.687C0.0899719 20.3562 0.0562324 22.1444 0 23.6177C0 24.2812 0.494845 24.5399 0.865979 24.5399C2.02812 24.5511 3.71884 24.5549 4.58107 24.4687C5.24836 24.3899 5.52202 24.2362 5.52577 23.4602L5.56326 14.8117C5.7582 14.7704 5.87816 14.8117 6.11059 14.8117C6.97282 14.8117 7.75633 14.9691 8.806 17.0647C10.4405 20.3674 11.0478 21.9082 11.9438 22.9954C12.9972 24.315 13.9756 24.5436 15.6251 24.5436C18.5642 24.5436 18.6167 23.3515 18.2455 22.8229C17.4883 21.732 16.8172 20.5399 15.5464 18.2718V18.2681ZM9.10965 11.0853C8.55857 11.3553 6.48172 11.4715 5.57826 11.3553L5.612 3.56519C6.00562 3.49021 6.55295 3.49021 7.0253 3.49021C9.37582 3.49021 11.1753 4.6711 11.164 7.62142C11.1528 10.1481 9.62324 10.8117 9.10965 11.0853Z"
                        fill="#3A6647"
                    />
                    <path
                        d="M137.338 7.76386C137.338 7.76386 115.329 1.82572 114.448 22.1931C114.448 22.1931 119.756 16.1162 128.206 11.5164C126.875 12.4386 118.973 18.0619 115.644 23.7151C138.812 27.6814 137.335 7.76386 137.335 7.76386H137.338Z"
                        fill="#3A6647"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_813_8042">
                        <rect width="137.357" height="32" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};
export default Logo;
