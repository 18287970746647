import { ClearButton, Container, FilePreview, FileUpload, HelperText } from './styles';
import { useRef } from 'react';
import { Clear } from '@mui/icons-material';
import { ReactComponent as ImageIcon } from 'assets/icons/Image.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/Person.svg';
import PropTypes from 'prop-types';

const UploadWithPreview = ({ text, onFileChange, value, error, helperText, defaultValue = '', rounded = false }) => {
    const fileInput = useRef();

    const handleClear = () => {
        fileInput.current.value = '';
        if (typeof onFileChange !== 'undefined') {
            onFileChange([], defaultValue);
        }
    };
    const handleChange = (evt) => {
        try {
            const url = URL.createObjectURL(evt.target.files[0]);
            if (typeof onFileChange !== 'undefined') {
                onFileChange(evt.target.files, url);
            }
        } catch (error) {
            handleClear();
        }
    };

    return (
        <Container>
            <FilePreview hasImage={Boolean(value)} rounded={rounded}>
                {value ? (
                    <>
                        <ClearButton onClick={handleClear} rounded={rounded}>
                            <Clear />
                        </ClearButton>
                        <img className={'blur'} src={value} alt={'blur'} />
                        <img className={'preview'} src={value} alt={'preview'} />
                    </>
                ) : (
                    <>{rounded ? <PersonIcon /> : <ImageIcon />}</>
                )}
            </FilePreview>
            <FileUpload>
                {text ?? 'Upload'}
                <input type={'file'} onChange={handleChange} ref={fileInput} accept={'image/jpeg, image/png'} />
            </FileUpload>
            {helperText && <HelperText hasError={error}>{helperText}</HelperText>}
        </Container>
    );
};
export default UploadWithPreview;

UploadWithPreview.propTypes = {
    text: PropTypes.string,
    onFileChange: PropTypes.func,
    value: PropTypes.any,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    defaultValue: PropTypes.string
};
