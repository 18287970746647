import React from 'react';
import Cookies from 'js-cookie';
import { removeCookie } from 'utils/cookies';
import { addHours } from '../../utils/date';
import useApi from '../api';

const useAuthService = () => {
    const { api } = useApi();
    return {
        logged: async () => {
            const token = Cookies.get('token');

            if (!token) {
                return false;
            }
            const response = await api
                .post('me')
                .then((resp) => {
                    return resp.data;
                })
                .catch((err) => {
                    return err.request;
                });

            return response.data;
        },
        authentication: async (email, password) => {
            return await api
                .post('login', {
                    email: email,
                    password: password
                })
                .then((data) => {
                    removeCookie('token');
                    const hours = 7 * 24;
                    Cookies.set('token', data.data.access_token, {
                        expires: addHours(new Date(), hours)
                    });
                    return data;
                })
                .catch((error) => {
                    return JSON.parse(error.request.response);
                });
        },
        recoverPassword: async (email) => {
            return await api
                .post('password/email', {
                    email
                })
                .catch((error) => {
                    return { message: JSON.parse(error.request.response).error };
                });
        },
        resetPassword: async (email, password, passwordConfirmation, token) => {
            return await api
                .post('password/reset', {
                    email,
                    password,
                    password_confirmation: passwordConfirmation,
                    token
                })
                .catch((error) => {
                    return JSON.parse(error.request.response);
                });
        },
        signUp: async (name, email, password, passwordConfirmation) => {
            const response = await api
                .post('register_student', {
                    name: name,
                    email: email,
                    password: password,
                    password_confirmation: passwordConfirmation,
                    acceptanceTerm: 1
                })
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    return JSON.parse(error.request.response);
                });

            if (response !== true) {
                throw new Error(Object.values(response.errorValidate)[0]);
            }

            return response;
        },
        logout: () => {
            removeCookie('token');
        }
    };
};

export default useAuthService;
