import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Content, Text, Actions, Button, Line } from './styles';
import { useDispatch, useSelector } from 'react-redux';

const Dialog = ({ title, text, submit, handler, open, cancelText, submitText, noButton, useReducer = false }) => {
    const confirm = useSelector((state) => state.confirmDialog);
    const dispatch = useDispatch();
    if (useReducer) {
        title = confirm.title;
        text = confirm.message;
        open = confirm.open;
        submit = confirm.onConfirm;
        handler = () => {
            dispatch({
                type: 'SET_CONFIRM_DIALOG',
                payload: {
                    open: false,
                    title: '',
                    message: '',
                    onConfirm: () => false
                }
            });
        };
    }
    const onSubmit = () => {
        if (typeof submit !== 'undefined') {
            submit();
        }
        if (typeof handler !== 'undefined') {
            handler();
        }
    };

    return (
        <Container open={open} onClose={handler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Title size="small" id="alert-dialog-title">
                {title}
            </Title>

            {text && <Line />}

            <Content>
                <Text>{text}</Text>
            </Content>
            {!noButton && (
                <Actions>
                    <Button cancel="true" size="small" onClick={handler}>
                        {cancelText || 'Cancelar'}
                    </Button>
                    <Button size="small" onClick={() => onSubmit()}>
                        {submitText || 'Continuar'}
                    </Button>
                </Actions>
            )}
        </Container>
    );
};

Dialog.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    submit: PropTypes.func,
    handler: PropTypes.func,
    open: PropTypes.bool,
    cancelText: PropTypes.string,
    submitText: PropTypes.string,
    noButton: PropTypes.bool,
    useReducer: PropTypes.bool
};

export default Dialog;
