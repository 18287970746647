import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 16px;
`;

export const Notification = styled.span`
    position: relative;
`;

export const Badge = styled.span`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
    background-color: red;
    position: absolute;
    top: 8px;
    right: 10px;
`;
