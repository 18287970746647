import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { Outlet, useLocation } from 'react-router-dom';
import Snackbar from 'components/molecules/alerts/Snackbar';
import { whitePages } from 'constants/components/rules';
import { CircularProgress } from '@mui/material';
import LoadingComponent from '../../../LoadingComponent';

const BlankLayout = ({ noPadding }) => {
    const location = useLocation();
    const whitePage = whitePages.includes(location.pathname);

    return (
        <Container whitePage={whitePage}>
            <Content noPadding={noPadding}>
                <React.Suspense fallback={<LoadingComponent />}>
                    <Outlet />
                </React.Suspense>
                <Snackbar />
            </Content>
        </Container>
    );
};

BlankLayout.propTypes = {
    noPadding: PropTypes.bool
};

export default BlankLayout;
