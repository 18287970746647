import Index from '../../pages';
import React from 'react';
import BlankLayout from '../../components/templates/layouts/Outlet/BlankLayout';
import Error404 from '../../pages/Errors/404';
const Diagnostico = React.lazy(() => import('pages/Diagnostico'));
const MainRoutes = {
    path: '/',
    element: <BlankLayout noPadding={true} />,
    children: [
        {
            path: '/diagnostico',
            element: <Diagnostico />
        },
        {
            path: '/',
            element: <Index />
        },
        {
            path: '*',
            element: <Error404 />
        }
    ]
};

export default MainRoutes;
