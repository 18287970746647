import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Spacer = ({ height }) => {
    return <Container height={height}></Container>;
};

Spacer.propTypes = {
    height: PropTypes.string
};

Spacer.defaultProps = {
    height: '50px'
};

export default Spacer;
