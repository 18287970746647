const initialState = {
    rightSidebar: false,
    rightSidebarContent: <></>,
    navbar: {
        searchBox: false
    },
    settingsModal: false,
    activeUser: [],
    categoryFilter: 1
};
const dashboardReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'DASHBOARD_OPEN_RIGHT_SIDEBAR':
            return { ...state, rightSidebar: true };
        case 'DASHBOARD_CLOSE_RIGHT_SIDEBAR':
            return { ...state, rightSidebar: false };
        case 'DASHBOARD_OPEN_SETTINGS_MODAL':
            return { ...state, settingsModal: true };
        case 'DASHBOARD_CLOSE_SETTINGS_MODAL':
            return { ...state, settingsModal: false };
        case 'DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT':
            return { ...state, rightSidebarContent: actions.payload };
        //NAVBAR
        case 'DASHBOARD_NAVBAR_SHOW':
            return { ...state, navbar: { ...state.navbar, searchBox: actions.payload } };
        case 'DASHBOARD_ACTIVE_USER':
            return { ...state, activeUser: actions.payload };
        case 'SET_CATEGORY_FILTER':
            return { ...state, categoryFilter: actions.payload };
        default:
            return state;
    }
};
export default dashboardReducer;
