export const initialState = {
    category: 1
};

const biHomeReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_CATEGORY':
            return {
                ...state,
                category: actions.payload
            };
        default:
            return state;
    }
};

export default biHomeReducer;
