import React from 'react';
import DashboardLayout from '../../components/templates/layouts/Outlet/DashboardLayout';

const Home = React.lazy(() => import('pages/Laticinio/Home'));
const Trilhas = React.lazy(() => import('pages/Laticinio/Trilhas'));
const Error404 = React.lazy(() => import('pages/Errors/404'));
const Producers = React.lazy(() => import('pages/Laticinio/Producers'));
const ProducersView = React.lazy(() => import('pages/Laticinio/Producers/View'));
const Module = React.lazy(() => import('pages/Admin/Module'));
const Diagnistic = React.lazy(() => import('pages/Laticinio/Diagnostic'));
const Avaliacao = React.lazy(() => import('pages/Laticinio/Avaliacao'));
const Help = React.lazy(() => import('pages/Ajuda'));
const LaticinioRoutes = [
    {
        path: '/laticinio',
        element: <DashboardLayout />,
        children: [
            {
                path: '/laticinio/painel',
                element: <Home />
            },
            {
                path: '/laticinio/trilhas',
                element: <Trilhas />
            },
            {
                path: '/laticinio/produtores',
                element: <Producers />
            },
            {
                path: '/laticinio/produtores/:id',
                element: <ProducersView />
            },
            {
                path: '/laticinio/unidade/:id',
                element: <Module />
            },
            {
                path: '/laticinio/avaliacao/:producerId',
                element: <Avaliacao />
            },
            {
                path: '/laticinio/diagnostico',
                element: <Diagnistic />
            },
            {
                path: '/laticinio/ajuda',
                element: <Help />
            },
            {
                path: '*',
                element: <Error404 />
            }
        ]
    }
];

export default LaticinioRoutes;
