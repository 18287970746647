import styled from 'styled-components';

export const Container = styled.div`
    width: ${(props) => (props.rightSidebar ? 'calc(100vw - var(--rightSidebar-width))' : '100vw')};
    height: var(--navbar-height);
    background-color: #fff;
    filter: drop-shadow(0px 1px 8px rgba(81, 69, 159, 0.05));
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    > div {
        display: flex;
        gap: 66px;
        align-items: center;
        justify-content: center;
    }
    > .logotipo img {
        height: 32px;
    }
`;
export const UserAvatar = styled.img`
    height: 58px;
    width: 58px;
    border-radius: 50%;
    object-fit: contain;
    box-shadow: 0 0 1px black;
`;
