import { Container } from './styles';
import Logo from '../../../components/atoms/svgs/Logo';
import { CircularProgress } from '@mui/material';

const LoadingComponent = () => {
    return (
        <Container>
            <Logo />
            <CircularProgress />
        </Container>
    );
};
export default LoadingComponent;
