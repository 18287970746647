import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../buttons/IconButton';
import ReactHtmlParser from 'html-react-parser';
import { NotificationModal, Notification } from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { Close } from '@mui/icons-material';
import useNotificationsService from 'services/notifications';

const NotificationsModal = ({ open, handleClose, notifications }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notificationService = useNotificationsService();

    const handleClickNotification = (id, url) => {
        notificationService.markAsRead(id).then((resp) => {
            if (url.includes('produtores')) {
                dispatch({
                    type: 'SET_PRODUCER_INVALIDATED',
                    payload: true
                });
            }
            handleClose();
            navigate(url);
        });
    };
    return (
        <NotificationModal fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle
                onClose={handleClose}
                sx={{
                    color: '#3A6647',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <span>Notificações</span>
                <IconButton variant={'transparent'} icon={<Close />} onClick={handleClose} />
            </DialogTitle>
            {notifications.length > 0 &&
                notifications.map((notification) => (
                    <DialogContent style={{ overflowY: 'visible' }} dividers key={notification.id}>
                        <Notification>
                            <h3>{ReactHtmlParser(notification.text)}</h3>
                            <Button variant={'contained'} onClick={() => handleClickNotification(notification.id, notification.url)}>
                                {notification.button}
                            </Button>
                        </Notification>
                    </DialogContent>
                ))}
        </NotificationModal>
    );
};

export default NotificationsModal;

NotificationsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func
};
