import React from 'react';
import DashboardLayout from '../../components/templates/layouts/Outlet/DashboardLayout';

const Home = React.lazy(() => import('pages/Admin/Home'));
const Trilhas = React.lazy(() => import('pages/Admin/Trilhas'));
const Laticinios = React.lazy(() => import('pages/Admin/Laticinios'));
const LaticiniosView = React.lazy(() => import('pages/Admin/Laticinios/View'));
const Producers = React.lazy(() => import('pages/Admin/Producers'));
const ProducersView = React.lazy(() => import('pages/Admin/Producers/View'));
const Module = React.lazy(() => import('pages/Admin/Module'));
const Diagnostic = React.lazy(() => import('pages/Admin/Diagnostic'));
const NSM = React.lazy(() => import('pages/Admin/NSM'));
const Avaliacao = React.lazy(() => import('pages/Admin/Avaliacao'));
const Help = React.lazy(() => import('pages/Ajuda'));
const Error404 = React.lazy(() => import('pages/Errors/404'));
const RehagroRoutes = [
    {
        path: '/admin',
        element: <DashboardLayout />,
        children: [
            {
                path: '/admin/painel',
                element: <Home />
            },
            {
                path: '/admin/trilhas',
                element: <Trilhas />
            },
            {
                path: '/admin/laticinios',
                element: <Laticinios />
            },
            {
                path: '/admin/laticinios/:id',
                element: <LaticiniosView />
            },
            {
                path: '/admin/produtores',
                element: <Producers />
            },
            {
                path: '/admin/produtores/:id',
                element: <ProducersView />
            },
            {
                path: '/admin/modulo',
                element: <Module />
            },
            {
                path: '/admin/diagnostico',
                element: <Diagnostic />
            },
            {
                path: '/admin/unidade/:id',
                element: <Module />
            },
            {
                path: '/admin/avaliacao/:producerId',
                element: <Avaliacao />
            },
            {
                path: '/admin/nsm',
                element: <NSM />
            },
            {
                path: '/admin/ajuda',
                element: <Help />
            },
            {
                path: '*',
                element: <Error404 />
            }
        ]
    }
];

export default RehagroRoutes;
