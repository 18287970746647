const sidebarData = [
    {
        label: 'Home',
        path: '/painel',
        icon: (
            <>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15 23V17C15 16.7348 14.8946 16.4805 14.7071 16.2929C14.5196 16.1054 14.2652 16 14 16H10C9.73478 16 9.48043 16.1054 9.29289 16.2929C9.10536 16.4805 9 16.7348 9 17V23C9 23.2652 8.89464 23.5196 8.70711 23.7071C8.51957 23.8947 8.26522 24 8 24H2C1.73478 24 1.48043 23.8947 1.29289 23.7071C1.10536 23.5196 1 23.2652 1 23V11.4375C1.00224 11.2991 1.03215 11.1626 1.08796 11.0359C1.14378 10.9093 1.22437 10.7951 1.325 10.7L11.325 1.61252C11.5093 1.44387 11.7501 1.35034 12 1.35034C12.2499 1.35034 12.4907 1.44387 12.675 1.61252L22.675 10.7C22.7756 10.7951 22.8562 10.9093 22.912 11.0359C22.9679 11.1626 22.9978 11.2991 23 11.4375V23C23 23.2652 22.8946 23.5196 22.7071 23.7071C22.5196 23.8947 22.2652 24 22 24H16C15.7348 24 15.4804 23.8947 15.2929 23.7071C15.1054 23.5196 15 23.2652 15 23Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </>
        ),
        iconType: 'stroke'
    },
    {
        label: 'Trilhas',
        path: '/trilhas',
        icon: (
            <>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5 28C6.65685 28 8 26.6569 8 25C8 23.3431 6.65685 22 5 22C3.34315 22 2 23.3431 2 25C2 26.6569 3.34315 28 5 28Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M27 10C28.6569 10 30 8.65685 30 7C30 5.34315 28.6569 4 27 4C25.3431 4 24 5.34315 24 7C24 8.65685 25.3431 10 27 10Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M10.575 14.6375L6.42499 22.3625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.875 17.875L14.125 14.125" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M25.575 9.63745L21.425 17.3625" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </>
        ),
        iconType: 'stroke'
    },
    {
        label: 'Produtores',
        path: '/produtores',
        icon: (
            <>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17 22.3333C20.6819 22.3333 23.6667 19.3486 23.6667 15.6667C23.6667 11.9848 20.6819 9 17 9C13.3181 9 10.3333 11.9848 10.3333 15.6667C10.3333 19.3486 13.3181 22.3333 17 22.3333ZM17 22.3333C14.784 22.3333 12.6112 22.9459 10.7225 24.1052C8.8339 25.2644 7.30315 26.924 6.29997 28.9M17 22.3333C19.216 22.3333 21.3888 22.9459 23.2774 24.1052C25.166 25.2644 26.6968 26.924 27.7 28.9M33 17C33 25.8366 25.8366 33 17 33C8.16344 33 1 25.8366 1 17C1 8.16344 8.16344 1 17 1C25.8366 1 33 8.16344 33 17Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </>
        ),
        iconType: 'stroke'
    },
    {
        label: 'Laticínios',
        path: '/laticinios',
        icon: (
            <>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 22H13.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18.5 22H22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M27 27V17H21L13 11V17L5 11V27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 27H30" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        d="M27 17L25.125 3.8625C25.0919 3.62391 24.9738 3.40529 24.7923 3.24683C24.6109 3.08837 24.3784 3.00072 24.1375 3H21.8625C21.6216 3.00072 21.3891 3.08837 21.2077 3.24683C21.0262 3.40529 20.9081 3.62391 20.875 3.8625L19.1875 15.65"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </>
        ),
        iconType: 'stroke'
    },
    {
        label: 'NSM',
        path: '/nsm',
        icon: (
            <>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.8333 19.8333L20.2265 20.7528C20.4567 20.6543 20.6416 20.4732 20.7446 20.245L19.8333 19.8333ZM24.5167 9.46663L25.428 9.87834C25.5994 9.49891 25.5178 9.05297 25.2231 8.75884C24.9284 8.46472 24.4823 8.38397 24.1032 8.55612L24.5167 9.46663ZM14.1667 14.1666L13.7532 13.2561C13.5259 13.3594 13.3454 13.5439 13.2472 13.7735L14.1667 14.1666ZM9.93333 24.0666L9.01386 23.6735C8.85321 24.0492 8.93729 24.4848 9.22622 24.7737C9.51515 25.0627 9.9508 25.1468 10.3265 24.9861L9.93333 24.0666ZM32 17C32 25.2843 25.2843 32 17 32V34C26.3888 34 34 26.3888 34 17H32ZM17 32C8.71573 32 2 25.2843 2 17H0C0 26.3888 7.61116 34 17 34V32ZM2 17C2 8.71573 8.71573 2 17 2V0C7.61116 0 0 7.61116 0 17H2ZM17 2C25.2843 2 32 8.71573 32 17H34C34 7.61116 26.3888 0 17 0V2ZM20.7446 20.245L25.428 9.87834L23.6053 9.05493L18.922 19.4216L20.7446 20.245ZM24.1032 8.55612L13.7532 13.2561L14.5801 15.0772L24.9301 10.3772L24.1032 8.55612ZM13.2472 13.7735L9.01386 23.6735L10.8528 24.4598L15.0861 14.5598L13.2472 13.7735ZM10.3265 24.9861L20.2265 20.7528L19.4402 18.9138L9.54016 23.1472L10.3265 24.9861Z" />
                </svg>
            </>
        ),
        iconType: 'fill'
    },
    {
        label: 'Diagnostico',
        path: '/diagnostico',
        icon: (
            <>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 19H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 15H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        d="M20 5H25C25.2652 5 25.5196 5.10536 25.7071 5.29289C25.8946 5.48043 26 5.73478 26 6V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V6C6 5.73478 6.10536 5.48043 6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5H12"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11 9V8C11 6.67392 11.5268 5.40215 12.4645 4.46447C13.4021 3.52678 14.6739 3 16 3C17.3261 3 18.5979 3.52678 19.5355 4.46447C20.4732 5.40215 21 6.67392 21 8V9H11Z"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </>
        ),
        iconType: 'stroke'
    }
];
export default sidebarData;
