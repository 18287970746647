import useApi from '../api';

const useCategoryService = () => {
    const { api } = useApi();
    return {
        getCategory: () => api.get('category').then((resp) => resp.data)
    };
};

export default useCategoryService;
