export const initialState = {
    open: false,
    title: '',
    message: '',
    onConfirm: () => {}
};

const confirmDialogReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_CONFIRM_DIALOG':
            return {
                ...state,
                ...actions.payload
            };

        default:
            return state;
    }
};

export default confirmDialogReducer;
