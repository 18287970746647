import React from 'react';
import DashboardLayout from '../../components/templates/layouts/Outlet/DashboardLayout';

const Home = React.lazy(() => import('pages/Producer/Home'));
const Help = React.lazy(() => import('pages/Ajuda'));
const Diagnostic = React.lazy(() => import('pages/Producer/Diagnostic'));
const Error404 = React.lazy(() => import('pages/Errors/404'));
const ProducerRoutes = [
    {
        path: '/produtor',
        element: <DashboardLayout />,
        children: [
            {
                path: '/produtor/painel',
                element: <Home />
            },
            {
                path: '/produtor/ajuda',
                element: <Help />
            },
            {
                path: '/produtor/diagnostico',
                element: <Diagnostic />
            },
            {
                path: '*',
                element: <Error404 />
            }
        ]
    }
];

export default ProducerRoutes;
