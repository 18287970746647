import React, { useEffect, useState } from 'react';
import useAuthService from 'services/auth';
import { Container, MenuItem } from './styles';
import sidebarData from './data';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DashboardSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [sideBarContent, setSideBarContent] = useState([]);
    const user = useSelector((state) => state.auth.user);

    const handleClickMenu = (location) => {
        if (user.idRole == 1) {
            location = '/admin' + location;
        } else if (user.idRole == 2) {
            location = '/laticinio' + location;
        } else {
            location = '/produtor' + location;
        }
        navigate(location);
    };

    const fillSideBarContent = () => {
        if (user.idRole == 1) {
            setSideBarContent(sidebarData);
        } else if (user.idRole == 2) {
            setSideBarContent(sidebarData.filter((elem) => elem.label != 'NSM' && elem.label != 'Laticínios'));
        } else {
            setSideBarContent(sidebarData.filter((elem) => elem.label == 'Home' || elem.label == 'Diagnostico'));
        }
    };

    useEffect(() => {
        fillSideBarContent();
    }, []);

    return (
        <Container>
            {!isLoading &&
                sideBarContent.length > 0 &&
                sideBarContent.map((menuItem, index) => (
                    <MenuItem
                        key={`menuItem-${index}`}
                        className={location.pathname.includes(menuItem.path) && 'active'}
                        onClick={() => handleClickMenu(menuItem.path)}
                    >
                        <span className={`icon ${menuItem.iconType}`}>
                            {React.isValidElement(menuItem.icon) ? <>{menuItem.icon}</> : <img src={menuItem.icon} alt={menuItem.label} />}
                        </span>
                        <span className={'title'}>{menuItem.label}</span>{' '}
                    </MenuItem>
                ))}
        </Container>
    );
};
export default DashboardSidebar;
