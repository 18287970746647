import styled from 'styled-components';
import auth from 'assets/bgs/auth.png';

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: #fff;

    > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 418px;
        max-width: 418px;
        height: 100%;
        background: url(${auth}) center center;
        background-size: cover;

        :after {
            content: '';
            position: absolute;
            right: 0;
            width: 8px;
            height: 100vh;
            background: linear-gradient(180deg, #78c75e 0%, #daf79c 34.17%, #7abf12 100%);
        }

        @media (min-width: 0) and (max-width: 992px) {
            display: none;
        }
    }

    > main {
        display: block;
        flex-grow: 1;
        width: 100%;

        > div {
            max-width: 418px;
            margin: 0 auto;
        }

        .logo {
            text-align: center;
        }
    }
`;
