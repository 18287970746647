import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { CloseButton } from './styles';
import IconButton from '../../buttons/IconButton';

const BaseModal = ({ title, open, withActions, children, handleClose }) => {
    return (
        <Dialog
            fullWidth
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{ sx: { width: { xs: '95%', sm: '80%', md: '60%' } } }}
            maxWidth={null}
        >
            <DialogTitle
                onClose={handleClose}
                sx={{
                    color: '#3A6647',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <span>{title}</span>
                <IconButton variant={'transparent'} icon={<Close />} onClick={handleClose} />
            </DialogTitle>
            <DialogContent dividers>
                <>{children}</>
            </DialogContent>
            {React.isValidElement(withActions) && <DialogActions>{withActions}</DialogActions>}
        </Dialog>
    );
};
export default BaseModal;

BaseModal.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    withActions: PropTypes.element,
    children: PropTypes.node.isRequired,

    handleClose: PropTypes.func.isRequired
};
