export const initialState = {
    optionMenu: 'Dashboard'
};

const tabsDiagnosticReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_OPTION_MENU':
            return {
                ...state,
                optionMenu: actions.payload
            };
        default:
            return state;
    }
};

export default tabsDiagnosticReducer;
