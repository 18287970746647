import styled from 'styled-components';
import { Dialog } from '@mui/material';

export const NotificationModal = styled(Dialog)`
    & .MuiDialog-paper {
        position: absolute !important;
        right: 0 !important;
        top: 50px !important;
        max-width: 600px !important;
        max-height: 600px;
    }
    > .MuiDialogContent-root {
        overflow-y: visible;
    }
`;

export const Notification = styled.div`
    > h3 {
        font-family: 'Myriad Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #25272e;
        margin-bottom: 16px;
    }
    > button {
        float: right;
    }
`;
