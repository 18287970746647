import styled from 'styled-components';

export const Button = styled.button`
    width: 42px;
    height: 42px;
    background-color: #fff;
    border: none;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    color: var(--color-primary);
    &:hover {
        filter: brightness(0.95);
    }
    &:active {
        filter: brightness(0.9);
    }
`;

export const TransparentButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 12px;
    padding: 8px;
    cursor: pointer;
    color: ${(props) => props.color} !important;
    border: none;

    > svg {
        width: 20px;
        height: 20px;

        > [stroke='current'] {
            stroke: ${(props) => props.color} !important;
        }
    }

    :disabled {
        color: #c6c6c6 !important;
        background-color: #f6f6f6;
        cursor: default;
        :hover {
            background: #f6f6f6;
        }
        :active {
            filter: brightness(1);
        }
    }

    :hover {
        background: ${(props) => props.bgColor};
    }

    :active {
        filter: brightness(0.95);
    }
`;
