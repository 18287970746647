import React from 'react';

import BlankLayout from 'components/templates/layouts/Outlet/BlankLayout';
import Cadastro from 'pages/Auth/Cadastro';
const Login = React.lazy(() => import('pages/Auth/Login'));
const RememberPassword = React.lazy(() => import('pages/Auth/RememberPassword'));
const AuthenticationRoutes = {
    path: '/',
    element: <BlankLayout noPadding={true} />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/remember_password',
            element: <RememberPassword />
        },
        {
            path: '/cadastro',
            element: <Cadastro />
        }
    ]
};

export default AuthenticationRoutes;
