import styled from 'styled-components';

export const Dropzone = styled.div`
    position: relative;
    width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: ${(props) => (props.error ? '2px dashed #cf322c' : '1px dashed #c6c6c6')};
    color: ${(props) => (props.error ? '#cf322c' : '#c6c6c6')};
    font-weight: ${(props) => (props.error ? 'bold' : 'normal')};
    background-color: #fbfbfb;
    padding: 10px;
    flex-direction: column;
    gap: 20px;

    &:hover {
        border: 2px dashed var(--color-primary);
        color: var(--color-primary);
        font-weight: bold;
    }
`;

export const DropzoneText = styled.p`
    width: 100%;
    text-align: center;
`;
export const Files = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    left: 10px;
    flex-wrap: wrap;
`;

export const File = styled.img`
    position: relative;
    width: calc(100% / 6 - 8.5px);
    height: 100px;
    object-fit: contain;
    border: 1px dashed #c6c6c6;
    &::after {
        content: 'abc';
        font-size: 24px;
        z-index: 1000;
    }
    &:hover {
        filter: blur(1px);
    }
`;
